'use strict'

document.addEventListener('DOMContentLoaded', ()=>{

  const menuObj = {
    header: {
      lineupLink: document.querySelector('.cmn-header_menu--link-lineup'),
      supportLink: document.querySelector('.cmn-header_menu--link-support'),
      lineupMenu: document.querySelector('.cmn-header_sub-lineup'),
      supportMenu: document.querySelector('.cmn-header_sub-support'),
      menuLinkAll: document.querySelectorAll('.cmn-header_menu--link')
    },
    footer: {
      lineupLink: document.querySelector('.cmn-footer_menu--nav_link-lineup'),
      supportLink: document.querySelector('.cmn-footer_menu--nav_link-support'),
      lineupMenu: document.querySelector('.cmn-footer_menu_sub-lineup'),
      supportMenu: document.querySelector('.cmn-footer_menu_sub-support'),
      menuLinkAll: document.querySelectorAll('.cmn-footer_menu--nav_link')
    }
  }
  function displaySubMenu(obj) {
    if(!obj.lineupLink)
      return

    // ヘッダーのリンク「ラインナップ」にホバーした時にメニュー表示
    obj.lineupLink.addEventListener('mouseover', ()=>{
      obj.lineupMenu.classList.add('js-show')
    })

    // ヘッダーのリンク「サポート」にホバーした時にメニュー表示
    obj.supportLink.addEventListener('mouseover', ()=>{
      obj.supportMenu.classList.add('js-show')
    })

    // 「ラインナップ」のメニューからホバーが外れた時にメニュー非表示
    obj.lineupMenu.addEventListener('mouseleave', ()=>{
      obj.lineupMenu.classList.remove('js-show')
    })

    obj.supportMenu.addEventListener('mouseleave', ()=>{
      obj.supportMenu.classList.remove('js-show')
    })

    // 該当リンク以外にホバーしたときの処理
    obj.menuLinkAll.forEach((currentLink)=>{
      currentLink.addEventListener('mouseover', ()=>{

        // ヘッダーのリンク「ラインナップ」以外にホバーした時にメニュー非表示
        if( currentLink !== obj.lineupLink ){
          obj.lineupMenu.classList.remove('js-show')
        }

        // ヘッダーのリンク「サポート」以外にホバーした時にメニュー非表示
        if( currentLink !== obj.supportLink ){
          obj.supportMenu.classList.remove('js-show')
        }
      })
    })
  }

  /** ヘッダー関連の処理 */
  if ( window.matchMedia( "(max-width: 428px)" ).matches )
  {
    // SP表示

    /** メニューボタンクリック処理 */
    const headerElement = document.querySelector('.cmn-header')
    const menuBtnElement = document.querySelector('.cmn-header_menu_btn')
    menuBtnElement.addEventListener('click', ()=>{
      if ( headerElement.classList.contains('js-open') )
      {
        // メニュー表示→閉じる

        // メニュー閉じる・アイコンの表示切り替え
        headerElement.classList.remove('js-open')

        // メニューボタンのテキスト切り替え
        const menuBtnTextElement = menuBtnElement.querySelector('.cmn-header_menu_btn--text')
        menuBtnTextElement.innerHTML = 'MENU'
      }
      else
      {
        // メニュー非表示→表示する

        // メニュー表示する・アイコンの表示切り替え
        headerElement.classList.add('js-open')

        // メニューボタンのテキスト切り替え
        const menuBtnTextElement = menuBtnElement.querySelector('.cmn-header_menu_btn--text')
        menuBtnTextElement.innerHTML = 'CLOSE'
      }
    })

    /** サブメニューのアコーディオン表示 */
    const itemWithSubMenuElements = document.querySelectorAll('.cmn-header_menu--item-lineup, .cmn-header_menu--item-support')
    itemWithSubMenuElements.forEach((itemElement)=>{
      itemElement.addEventListener('click', (e)=>{
        // リンクの文字以外をクリックでサブメニュー表示
        if( !e.target.closest('.cmn-header_menu--link') )
        {
          const subMenuElement = $(itemElement).find('.cmn-header_sp_sub')
          subMenuElement.slideToggle();
          itemElement.classList.toggle('js-sub_show')

        }
      })
    })
  }
  else
  {
    // PC表示

    /** サブメニュー表示処理 */
    displaySubMenu(menuObj.header)
    displaySubMenu(menuObj.footer)
  }
})

$(function(){
  // page top button
  $(window).scroll(function(){
    var position = $(window).scrollTop();
    if(position > 200){
      $(".cmn-page_top_btn").css({"opacity":"1","bottom":"20px"})
    } else {
      $(".cmn-page_top_btn").css({"opacity":"0","bottom":"10px"})
    }
  })

  // topics.inc
  if($('.cmn-footer_topics').length) {
    if ($('.cmn-footer_topics--item').length > 3) {
      $('.cmn-footer_topics--list').slick(
          {
            arrows: true,
            slidesToShow: 3,
            variableWidth: false,
            centerMode: false,
            responsive: [
              {
                breakpoint: 428,
                settings:
                    {
                      arrows: true,
                      slidesToShow: 1,
                      variableWidth: false,
                      centerMode: true
                    }
              }
            ]
          }
      );
    } else if (window_w < 428) {
      $('.cmn-footer_topics--list').slick(
          {
            arrows: true,
            slidesToShow: 1,
            variableWidth: false,
            centerMode: true,
            dots: true,
          }
      );
    }
  }

  /***
   * [blog.inc(おもいでばこブログ)]のDOMを書き換えます
   * タイトルは切り詰め処理を入れます。
   * AJAX処理終了後にobject-fitポリフィルを実行します。
   */
      // [ピックアップ記事]書き換え(ピックアップタグ(brand-pickup)が付与された記事に書き換え)
  var EXCERPT_TITLE_LENGTH = 35;
  $.ajax({
    type: 'GET',
    url: 'https://omoidebako.jp/blog/wp-json/posts?filter[tag]=brand-pickup',
    dataType: 'json',
    /**
     * @param {Array<article>} json
     */
    success: function (json) {
      // ピックアップ記事一覧はcmn-blog_container--boxクラスが付与されているDOMの1個目とします
      var blogContainer = $('.cmn-footer_blog--article_wrapper');
      var pickupArticles = $(blogContainer[0]).find('.cmn-footer_blog_article');

      for (var i = 0; i < pickupArticles.length; i++) {
        var cmnBlogArticle = pickupArticles[i];
        var articleDate = moment(json[i].date).format('D MMM YYYY').toUpperCase();
        var cssModifier = chooseModifierWithCategoryName(json[i].terms.category[0].name);

        // リンク書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--link').eq(0).attr('href', json[i].link);

        // 画像src書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--img').eq(0).bind('load', function () {
          objectFitImages(this); // object-fit polyfillを画像ロード後に実行(IE11対策)
        });
        $(cmnBlogArticle).find('.cmn-footer_blog_article--img').eq(0).attr('src', json[i].featured_image.guid);

        // カテゴリ書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--category').eq(0)
            .text(json[i].terms.category[0].name)
            .removeClass() // モディファイアまで全て外してクラス名を付与し直す
            .addClass('cmn-footer_blog_article--category')
            .addClass('cmn-footer_blog_article--category-' + cssModifier);

        // 日付書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--date').eq(0).text(articleDate);

        // タイトル書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--title').eq(0).text(getExcerptFromHTMLString(json[i].title), EXCERPT_TITLE_LENGTH);
      }
    }
  });

// [新着記事]書き換え
  $.ajax({
    type: 'GET',
    url: 'https://omoidebako.jp/blog/wp-json/posts',
    dataType: 'json',
    /**
     * @param {Array<article>} json
     */
    success: function (json) {
      // 新着記事一覧はcmn-blog_container--boxクラスが付与されているDOMの2個目とします
      var blogContainer = $('.cmn-footer_blog--article_wrapper');
      var recentArticles = $(blogContainer[1]).find('.cmn-footer_blog_article');

      for (var i = 0; i < recentArticles.length; i++) {
        var cmnBlogArticle = recentArticles[i];
        var articleDate = moment(json[i].date).format('D MMM YYYY').toUpperCase();
        var cssModifier = chooseModifierWithCategoryName(json[i].terms.category[0].name);
        // リンク書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--link').eq(0).attr('href', json[i].link);

        // 画像src書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--img').eq(0).bind('load', function () {
          objectFitImages(this); // object-fit polyfillを画像ロード後に実行(IE11対策)
        });
        $(cmnBlogArticle).find('.cmn-footer_blog_article--img').eq(0).attr('src', json[i].featured_image.guid);

        // カテゴリ書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--category').eq(0)
            .text(json[i].terms.category[0].name)
            .removeClass() // モディファイアまで全て外してクラス名を付与し直す
            .addClass('cmn-footer_blog_article--category')
            .addClass('cmn-footer_blog_article--category-' + cssModifier);

        // 日付書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--date').eq(0).text(articleDate);

        // タイトル書き換え
        $(cmnBlogArticle).find('.cmn-footer_blog_article--title').eq(0).text(getExcerptFromHTMLString(json[i].title), EXCERPT_TITLE_LENGTH);
      }
    }
  });

  // howtoページ（ajaxがsmooziee.jsに影響するので、howto.es6から移行）
  function createRelatedPostElement(title, img_url, link) {
    return $.parseHTML('<img src="'+img_url+'" alt="" class="m-blog--img">\n' +
        '            <a href="'+link+'" target="_blank" class="m-blog--article_link">'+title+'</a>\n');
  }

  function how_to(tagstr) {
    var dfd = $.Deferred();
    if( !document.getElementById(tagstr) )
    {
      dfd.resolve();
      return dfd.promise();
    }

    var DISPLAY_MAX_POST = 1; // 最大表示件数
    $.ajax(
        {
          type: 'GET',
          url: 'https://omoidebako.jp/blog/wp-json/posts?filter[tag]=' + tagstr,
          dataType: 'json'
        }
    ).done(function (data) {
      data.forEach(function (e, i) {
        if (DISPLAY_MAX_POST <= i) return;
        var postElement = createRelatedPostElement(e.title, e.featured_image.guid, e.link );
        $('#' + tagstr).append(postElement);
        dfd.resolve();
      });
    });
    return dfd.promise();
  }

  $.when(
      how_to('how-to-take-in'),
      how_to('how-to-auto'),
      how_to('how-to-enjoy'),
      how_to('how-to-outdoor'),
      how_to('how-to-more'),
      how_to('googlephoto-upload'),
  ).then( function (){
    $.smooziee({offset: -85});
  });
});

/***
 * カテゴリの名前からクラスに付与するモディファイア(色)を選びます。
 * /review/ ページ共通のため、グローバル宣言しています。
 * @param categoryName モディファイアを決定するために利用するカテゴリ名
 * @return null | String cssクラスに付与するモディファイア
 */
function chooseModifierWithCategoryName(categoryName) {
  const modifiers = [
    {
      'categoryName': '写真整理',
      'modifier': 'orange'
    },
    {
      'categoryName': 'iPhone写真術',
      'modifier': 'orange'
    },
    {
      'categoryName': '開発の歩み',
      'modifier': 'green'
    },
    {
      'categoryName': 'イベント',
      'modifier': 'green'
    },
    {
      'categoryName': 'アンバサダー',
      'modifier': 'blue'
    },
    {
      'categoryName': 'Tips',
      'modifier': 'blue'
    },
    {
      'categoryName': 'ビジネス',
      'modifier': 'yellow'
    },
    {
      'categoryName': 'インタビュー',
      'modifier': 'yellow'
    },
    {
      'categoryName': '子どもと写真',
      'modifier': 'pink'
    },
    {
      'categoryName': 'お知らせ',
      'modifier': 'pink'
    },
    {
      'categoryName': '導入事例',
      'modifier': 'purple'
    }
  ];
  let result = null;
  for (var i = 0; i < modifiers.length; i++) {
    if (modifiers[i].categoryName === categoryName) result = modifiers[i].modifier;
  }
  return result;
}

/***
 * 指定されたテキストの概要を返します。
 * HTMLタグを含む文字列からHTMLタグを取り除いて、指定した長さを超える場合は短く詰めて三点リーダを付与して返します。
 * 超えない場合はHTMLタグを取り除いた文字列を返します。
 * @param htmlString タグを含む文字列
 * @param MAX_LENGTH 切り詰める文字の長さ
 * @return {string} タグを取り除いた文字列
 */
function getExcerptFromHTMLString(htmlString, MAX_LENGTH) {
  let result;
  let div = $('<div></div>'); // HTMLをパースするために空divを利用します
  div.html(htmlString);
  const text = div.text();
  if (MAX_LENGTH < text.length) {
    result = text.substring(0, MAX_LENGTH) + '…';
  } else {
    result = text;
  }
  return result;
}
